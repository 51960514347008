import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../constants/theme';

const Letter = styled.div`
  align-items: center;
  border-color: currentcolor currentcolor rgb(107, 37, 123);
  border-radius: 0;
  border-style: none none solid;
  border-width: medium medium 1px;
  box-shadow: none;
  color: rgb(107, 37, 123);
  display: flex;
  font-size: 2rem;
  font-weight: 400;
  height: 45px;
  justify-content: center;
  margin: 0 8px 8px;
  outline: currentcolor none medium;
  padding: 0;
  position: relative;
  text-align: center;
  width: 30px;
`;

const Caret = styled.span`
  animation: caretAnimation 0.7s infinite alternate;
  background-color: purple;
  content: '';
  display: flex;
  height: 2rem;
  position: absolute;
  width: 2px;

  @keyframes caretAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const LetterWrapper = styled.div(({ error }) => css`
    display: flex;
    ${error
&& css`
      > div {
        border-color: ${theme.colors.fontRed} !important;
        color: ${theme.colors.fontRed} !important;
      }
    `}
  `);

const Wrapper = styled.div`
  font-size: 2rem;
  position: relative;

  span {
    display: none;
  }

  input:focus + div {
    span {
      display: block;
    }
  }
`;
const Input = styled.input(() => css`
    background: transparent;
    border: none;
    color: transparent;
    font-size: 2rem;
    height: 4rem;
    letter-spacing: 30px;
    outline: none;
    padding: 0 15px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  `);

interface CodeInputProps {
    value: string;
    onChange: (value: string) => void;
    error?: boolean;
}

export const CodeInput: FC<CodeInputProps> = ({ value, onChange, error }) => {
	const [letterArray, setLetterArray] = useState(['', '', '', '', '', '']);
	// const [value, setValue] = useState('');

	const handleChange = (e) => {
		const newValue = e.target.value.replace(/[^a-zA-Z]+/g, '');
		if (newValue.split('').length > 6) return;
		// setValue(newValue);
		const valueArray = newValue.split('');
		const newFields = Array(6 - valueArray.length).fill('');
		const newArray = [...valueArray, ...newFields];
		setLetterArray(newArray);
		onChange(newValue);
	};

	return (
		<Wrapper>
			<Input
				onChange={handleChange}
				value={value}
			/>
			<LetterWrapper error={error}>
				{letterArray.map((letter, index) => (
					<Letter>
						{value.split('').length === index && <Caret />}
						{letter}
					</Letter>
				))}
			</LetterWrapper>
		</Wrapper>
	);
};
