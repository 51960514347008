import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SEO from '../components/seo';
import ManualEnterId from '../components/qr/manualEnterId';
import AppPageHeader from '../components/common/texts/appPageHeader';
import AppLeadingText from '../components/common/texts/appLeadingText';
import { SwitchEnterMode } from '../components/qr/switchEnterMode';
import { Loading } from '../components/common/loading';
import { AppContainer } from '../components/common/appContainer';
import { GlobalState } from '../state/@types/redux.interface';

const QRCodePage: FC = () => {
	const { t } = useTranslation();
	const tableSelector = useSelector((state: GlobalState) => state.table);
	const {
		loading, fetched, error, table,
	} = tableSelector;
	return (
		<>
			<SEO title={t('enterCode.title')} />
			<AppContainer>
				{loading && !error && <Loading />}
				<AppPageHeader>{t('enterCode.header')}</AppPageHeader>
				<AppLeadingText>{t('enterCode.description')}</AppLeadingText>
				<ManualEnterId />
				<SwitchEnterMode />
			</AppContainer>
		</>
	);
};
export default QRCodePage;
