import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getQueryParam } from '../../utils/queryParams';
import { CodeInput } from '../common/codeInput';
import { clearTableDetailsAction, fetchTableDetailsByCodeAction } from '../../state/table.reducer';
import { GlobalState } from '../../state/@types/redux.interface';

const ReactCodeInputWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.brandPurple};
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  font-weight: bold;
  justify-content: center;
  margin: 0;
  text-transform: uppercase;

  p {
    padding: 0 10px;

    &.error {
      color: ${({ theme }) => theme.colors.fontRed};
    }
  }
`;

const ManualEnterId: FC = () => {
	const [code, setCode] = useState('');
	const [inputVisible, setInputVisible] = useState(true);
	const table = useSelector((state: GlobalState) => state.table);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { error } = table;

	const onChange = (value) => {
		setCode(value);
		if (value && value.length === 6) {
			dispatch(clearTableDetailsAction());
			dispatch(fetchTableDetailsByCodeAction(value));
		}
	};

	useEffect(() => {
		const code = getQueryParam('code');
		if (code) {
			setInputVisible(false);
			onChange(code);
			setTimeout(() => {
				setInputVisible(true);
			}, 500);
		}
	}, []);

	return (
		<ReactCodeInputWrapper>
			{inputVisible && (
				<CodeInput
					error={error}
					onChange={onChange}
					value={code}
				/>
			)}
			{error && <p className="error">{t('enterCode.codeError')}</p>}
		</ReactCodeInputWrapper>
	);
};

export default ManualEnterId;
